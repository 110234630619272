import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "tagCloud", "errorContainer", "hiddenInputContainer"];
  static values = {
    validations: Array
  }

  handleInput(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      let errors = this.performValidations()
      if (errors.length === 0) {
        this.hideErrorsContainer()
        this.addTag();
      } else {
        this.displayErrors(errors)
      }
    }
  }

  hideErrorsContainer() {
    this.errorContainerTarget.innerHTML = ""
    this.errorContainerTarget.classList.add("hidden")
  }

  performValidations() {
    let errors = []
    this.validationsValue.forEach((validationName) => {
      let error = this.callValidation(validationName);
      if (error) {
        errors.push(error);
      }
    })

    return errors
  }

  callValidation(validationName) {
    const validationHandler = 'validate' + validationName.charAt(0).toUpperCase() + validationName.slice(1);
    const value = this.inputTarget.value;
    if (typeof this[validationHandler] === 'function') {
      return this[validationHandler](value);
    } else {
      console.error(`${validationHandler} does not exist`);
    }
  }

  validateEmail(value) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(value)) {
      return `${value} is not a valid email address.`
    }
  }

  validateUniqueness(value) {
    const inputs = this.hiddenInputContainerTarget.querySelectorAll("input")
    const duplicateFound = Array.from(inputs).some(i => i.value == value)

    if(duplicateFound) {
      this.inputTarget.value = ""
      return `${value} is already in the list.`
    }
  }

  displayErrors(errors) {
    this.errorContainerTarget.innerHTML = ""
    errors.forEach((error) => {
      let li = document.createElement('li');
      li.textContent = error;
      this.errorContainerTarget.appendChild(li);
      this.errorContainerTarget.classList.remove("hidden")
    })
  }

  addTag() {
    const value = this.inputTarget.value.trim();

    if (value) {
      const tagElement = this.generateTagElement(value, this.inputTarget.dataset.deletableName);
      this.tagCloudTarget.append(tagElement);
      this.addHiddenInput(value)
      this.inputTarget.value = "";
      this.inputTarget.focus();
    }
  }

  removeTag(event) {
    let parent = event.currentTarget.parentElement
    let obsoleteValue = parent.innerHTML.split("<span")[0]
    let obsoleteInput = document.querySelector("input[name='" + event.currentTarget.dataset.deletableName + "'][value='" + obsoleteValue + "']")
    parent.remove();
    obsoleteInput.remove();

    this.handleLastTagRemoval();
  }

  addHiddenInput(value) {
    const hiddenInput = document.createElement("input")
    hiddenInput.name = this.inputTarget.dataset.deletableName
    hiddenInput.autocomplete = "off"
    hiddenInput.type = "hidden"
    hiddenInput.value = value
    this.hiddenInputContainerTarget.appendChild(hiddenInput)
  }

  handleLastTagRemoval() {
    if (this.tagCloudTarget.children.length === 0) {
      this.addEmptyHiddenInput();
    }
  }

  addEmptyHiddenInput() {
    const hiddenInput = document.createElement("input");
    hiddenInput.name = this.inputTarget.dataset.deletableName;
    hiddenInput.type = "hidden";
    this.hiddenInputContainerTarget.appendChild(hiddenInput);
  }

  generateTagElement(content, deletableName) {
    const tag = document.createElement("span");
    tag.className = "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20 mr-2 my-1";
    tag.textContent = content;

    const closeButton = document.createElement("span");
    closeButton.dataset.action = "click->tags#removeTag"
    closeButton.classList.add("ml-2", "cursor-pointer")
    closeButton.dataset.deletableName = deletableName
    closeButton.textContent = "X";

    tag.appendChild(closeButton);
    return tag;
  }
}
