import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  copy() {
    let olElement = this.sourceTarget.querySelector('ol');
    let textToCopy;

    if (olElement) {
      let items = olElement.querySelectorAll('li');
      textToCopy = '';

      items.forEach((item, index) => {
        textToCopy += `${index + 1}. ${item.textContent}\n`;
      });
    } else {
      textToCopy = this.sourceTarget.textContent;
    }

    navigator.clipboard.writeText(textToCopy)
  }

  oneTimeCopy() {
    navigator.clipboard.writeText(event.target.dataset.content)
    event.target.innerText = "Copied!"
    event.target.removeAttribute("data-action")
    event.target.style.cursor = "default"
    event.target.classList.remove("bg-primary-100", "hover:bg-primary-300")
    event.target.classList.add("bg-emerald-400", "pointer-events-none")
  }
}
