import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { arguments: Array }

  loadOptions() {
    const parameterValue = event.target.value
    const args = JSON.parse(event.target.dataset.arguments)
    if(parameterValue) {
      args.forEach((argSet) => {
        const url = argSet.url + `?${argSet.parameter}=${parameterValue}`;
        const target = document.getElementById(argSet.target)
        this.fetchAndPopulate(url, target)
      })
    } else {
      this.clearValues(target)
    }
  }

  fetchAndPopulate(url, input){
    fetch(url, {
      headers:  {
        "Accept": "application/json"
      }
    })
    .then(response => response.json())
    .then(data => {
      const options = data.map(item => {
        return `<option value="${item[0]}">${item[1]}</option>`;
      }).join('');
      input.innerHTML = options;
      input.disabled = false;
    });
  }

  clearValues(input){
    input.innerHTML = [];
    input.disabled = true;
    input.value = null;
  }
}
